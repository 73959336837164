@font-face {
  font-family: "Montserrat";
  src: url("../Montserrat-VariableFont_wght.ttf");
}

.header-flex-reverse > div > div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
}

.font-montserrat {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.cell {
  color: #999999;
}

.cell a {
  color: #32558f;
  text-decoration: none;
  font-weight: 500 !important;
  letter-spacing: -0.02em;
}

.manully-updated > div {
  border: 1px solid #5aa700;
  border-radius: 4px;
}

.positionMatch .positionLower .positionHigher {
  display: flex;
  justify-content: center;
  align-items: center;
}

.positionMatch > div {
  text-align: center;

  width: 100%;
  background: rgba(122, 199, 12, 0.4);
  border-radius: 4px;
}

.positionLower > div {
  text-align: center;

  width: 100%;
  background: rgba(233, 53, 53, 0.1);
  border-radius: 4px;
}

.positionHigher > div {
  text-align: center;

  width: 100%;
  background: rgba(122, 199, 12, 0.15);
  border-radius: 4px;
}

.position-k-plus > div {
  text-align: center;
  width: 100%;
  background: rgba(114, 160, 236, 0.2);
  border-radius: 4px;
}

.positionOther > div {
  text-align: center;
  width: 100%;
}

.position-updated {
  border: 1px solid #5aa700;
}

.top-header {
  color: #5aa700;
}

.odd-row {
  background: #ffffff;
}

.even-row {
  background: #f9f9f9;
}

.header {
  padding: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;

  /* Shade / 300 */

  color: #b3b3b3;
}

.header span {
  margin: 3px;
  font-size: 12px;
}

.pinned-header {
  border-right: 1px solid #F2F2F2;
}

.query-header:hover .parse-icon {
  display: block !important;
}

.parse-icon {
  display: none !important;
}